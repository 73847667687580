$sp-badge-color1: #55bba6;
$sp-badge-color2: #7572b4;
$sp-badge-color3: #c4aa21;
$sp-badge-color4: #c47b21;
.speakers {
    &__area {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100vw;

        @media #{$xl_gt}{ //1200 이상
            width: 90vw;
        }
        @media #{$lg} {
            padding: 0 30px;
        }
        @media #{$lg_st} {
            padding: 0;
        }
    }

    &__content {
        max-width: 1800px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-top: 50px;

        @mixin textcss(){
            color: #083a3f;
            font-weight: bold;
            .ant-segmented-item-label{
                font-size: 20px;
            }
        }
        .ant-segmented{
            width: 100%;
            max-width: 300px;
            @include textcss;
            .ant-segmented-item{
                @include textcss;
            }
            margin-bottom: 40px;
        }
        .ant-segmented-group {
            justify-content: center;
            .ant-segmented-item{
                flex-grow: 1;
            }
        }

        .title {
            font-size: 30px;
            text-align: center;
            margin-bottom: 60px;
        }

        section{
            margin-bottom: 150px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .subTitle{
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                &-1 h3{background-color: $sp-badge-color1}
                &-2 h3{background-color: $sp-badge-color2}
                &-3 h3{background-color: $sp-badge-color3}
                &-4 h3{background-color: $sp-badge-color4}
                h3{
                    width: fit-content;
                    padding: 5px 8px;
                    border-radius: 7px;
                    color: white;
                }
            }
        }

        .profiles{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &__lastdesc{
        word-break: keep-all;
        padding: 0 20px;
    }

}

$pfC-bR:20px;
$pfc-w:530px;
$pfc-h:420px;
$pfc-h-min-xs:795px;
$pfc-h-xs:870px;

$pfc-w-simple:320px;
$pfc-w-simple-xs:310px;
$pfc-h-simple:420px;
$pfc-h-simple2:280px;
.ProfileCard{
    padding: 0 20px;
    margin-bottom: 30px;
    @media #{$xs} {
        padding: 5px;
    }
    .simple{
        width: $pfc-w-simple !important;
        height: $pfc-h-simple !important;
        min-height: auto !important;
    }
    .simple2{
        width: $pfc-w-simple !important;
        height: $pfc-h-simple2 !important;
        min-height: auto !important;
    }
    .btnArea{
        height: 50px;
        display: flex;
        justify-content: end;
        position: absolute;
        bottom: 15px;
        right: 24px;
        .docBtn{
            display: flex;
            align-items: center;
            height: 40px;
            font-weight: bold;
            background-color: rgb(57 71 107 / 91%);
            color: white;
            font-family: monospace;
            font-size: 18px;
        }
        .docBtn:hover{
            background-color: white;
            .docIcon{
                color: #4096ff;
            }
        }
        .docIcon{
            transition: all 0.3s ease-out 0s;
            color: white;
            width: auto;
            height: 20px;
        }
    }
    .ProfileWrapper{
        position: relative;
        width: $pfc-w;
        height: $pfc-h;
        @media #{$xs} {
            width: $pfc-w-simple-xs;
            min-height: $pfc-h-min-xs;
        }
        .front{
            display: flex;
            padding: 20px;
            @media #{$xs} {
                display: flex;
            }
            .left-and-vtext{
                display: flex;
                width: 300px;
                &.w200{
                    width: 200px;
                }
                .left{
                    $mb: 5px;
                    display: flex;
                    flex-direction: column;
                    width: 200px;
                    min-width: 240px;
                    height: 380px;
                    border-radius: $pfC-bR;
                    .left-top-1{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        word-break: keep-all;
                        margin-bottom: $mb;
                        .nameAndDetail{
                            display: flex;
                            align-items: flex-end;
                            .underline__blue{
                                font-weight: 600;
                                font-size: 14px;
                            }
                        }
                        .prfName{
                            color: white;
                            background-color: #244bb3;
                            border-radius: 10px;
                            padding: 13px 13px;
                            margin-right: 5px;
                            font-size: 30px;
                            //@media #{$xs} {
                            //    font-size: 15px;
                            //}
                        }

                    }
                    .left-top-2{
                        .belong{
                            .prfCompany{
                                margin-bottom: $mb;
                                font-weight: 600;
                            }
                            .prfPosition{
                                margin-bottom: $mb;
                                height: 24px;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                        .belong-type3{
                            height: 58px;
                        }
                    }
                    .left-subject{
                        height: 73px;
                        font-weight: bold;

                        .quotes{
                            margin-bottom: 13px;
                        }
                    }
                    .font-sm{
                        font-size: 14px;
                        margin-bottom: 3px;
                    }
                    .left-photo{
                        $ratio: 20/27;
                        width: 200px;
                        height: auto;
                        flex-grow: 1;
                        img{
                            border-radius: 100px;
                            height: auto;
                            max-width: 100%;
                            display: block;
                        }
                        .coming_soon {
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                        }
                    }
                }
                .vertical-text-wraaper{
                    display: flex;
                    justify-content: end;
                    width: 100%;
                    &.align-end{
                        align-items: flex-end;
                    }
                    .vertical-text-num{
                        font-size: 40px;
                        font-weight: bold;
                        &-c1{color: $sp-badge-color1;}
                        &-c2{color: $sp-badge-color2;}
                        &-c3{color: $sp-badge-color3;}
                        &-c4{color: $sp-badge-color4;}
                        .vertical-text{
                            writing-mode: vertical-rl;
                        }
                        .vertical-num{
                            padding-top: 5px;
                        }
                    }
                }
            }
            .right{
                width: 100%;
                margin-left: 20px;
                @media #{$xs} {
                    margin-left: 0;
                    margin-top: 20px;
                    width: 280px;
                }
                .prfBadge-box{
                    height: 93px;
                    display: flex;
                    justify-content: end;
                    align-items: flex-start;
                    @media #{$xs} {
                        justify-content: flex-start;
                    }
                    .prfBadge{
                        width: fit-content;
                        border-radius: 20px;
                        padding: 13px 13px;
                        color: white;
                        font-size: 30px;
                        font-weight: bold;
                        &-c1{background-color: $sp-badge-color1;}
                        &-c2{background-color: $sp-badge-color2;}
                        &-c3{background-color: $sp-badge-color3;}
                        &-c4{background-color: $sp-badge-color4;}
                    }
                }
                .right-title{
                    margin-bottom: 20px;
                    overflow-wrap: anywhere;
                    display: flex;
                    img{
                        width: 20px;
                        height: 15px;
                    }
                    .ddaom-1{
                        margin-right: 3px;
                    }

                    h4{
                        display: flex;
                    }
                }
                .right-desc{

                }
            }
        }
        .back{
            color:white;
            .up{
                visibility: hidden;
                opacity: 0;
                &-visible{
                    transition: 0.8s ease-out;
                    visibility: visible;
                    opacity: 1;
                    padding: 20px 20px 20px 40px;
                    li{
                        list-style: square;
                    }
                }
            }
            .down{
                visibility: hidden;
                opacity: 0;
                &-visible{
                    transition: 0.8s ease-out;
                    visibility: visible;
                    opacity: 1;
                    padding: 20px;
                }
            }
        }
        .pfCard{
            &-Container{
                border-radius: $pfC-bR;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
        .m-column{
            @media #{$xs} {
                flex-direction: column;
            }
        }
        .toggleBox{
            height: 0;
            transition: height 0.5s ease-in-out;
        }
        .toggleBox.visible{
            height: 100%;
            opacity: 0.95;
        }
        .gradient1{
            background-image: linear-gradient(135deg, #f5f7fa 0%, #dfe8f5 100%);
        }
        .gradient2{
            background-image: linear-gradient(135deg, #9db4d4 0%, #7b94ba 100%);
        }
        .z2{
            z-index: 2;
        }
    }
}
.m-mb-100{
    @media #{$xs} {
        margin-bottom: 100px;
    }
    &:last-child{
        @media #{$xs} {
            margin-bottom: 0px;
        }
    }
}

//추가 커스텀
.speakers__content> section:nth-of-type(3) .profiles>.ProfileCard:nth-child(5) .prfPosition{
    font-size: 11px !important;
}
.speakers__content> section:nth-of-type(4) .profiles>.ProfileCard:nth-child(2) .up-visible{
    font-size: 14px !important;
}
