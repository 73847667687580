/*----------------------------------------*/
/*  20. PAGE TITLE CSS START
/*----------------------------------------*/

.page {
    &__title {
        background: url("../img/2024design/keyvisual_background-1.webp");
        background-position: center;
        background-size: cover;
        min-height: 250px;

        &-2 {
            min-height: 700px;
            @media #{$sm}{
                min-height: 500px;
            }
            @media #{$xs}{
                min-height: 500px;
            }
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            gap: 20px;
            padding-bottom: 40px;

            & h2 {
                font-size: 60px;
                color: $white;
                @media #{$sm}{
                    font-size: 50px;
                }
                @media #{$xs}{
                    font-size: 24px;
                }
            }

            & .breadcrumb {
                padding: 0;
                background-color: transparent;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                margin-bottom: 0;

                &-item {
                    color: $white;
                    font-size: 18px;
                    @media #{$xs}{
                        font-size: 15px;
                    }

                    &:hover {
                        color: $white;
                    }

                    &.active {
                        padding-left: 14px;

                        &:hover {
                            color: $white;
                        }

                        &::before {
                            content: '|';
                            color: $white;
                            padding-right: 14px;
                        }
                    }
                }
            }

            & span {
                color: $white;
                opacity: .5;
                display: inline-block;
                margin-bottom: 15px;
            }

            & h3 {
                font-size: 70px;
                color: $white;
                @media #{$sm}{
                    font-size: 50px;
                }
                @media #{$xs}{
                    font-size: 30px;
                }
            }
        }
    }
}