/*----------------------------------------*/
/*  23. Login CSS START
/*----------------------------------------*/

.program{
    &__area{
        display: flex;
        justify-content: center;
        padding: 60px 0;
        & h1{
            text-align: center;
        }
    }
    &__title{
        text-align: center;
        margin-bottom: 40px;
    }
    &__content{
        display: flex;
        flex-direction: column;
        max-width: 900px;
        padding: 0 40px 0;

        @media #{$lg_gt}{
            width: 900px;
            padding-left: 0px;
            padding-right: 0px;
        }
        @media #{$lg_st}{
            width: 90vw;
            padding-left: 0px;
            padding-right: 0px;
        }
        & .imgBox{
            width: 100%;
        }
        & .gridTable{
            font-family: $font-nps-regular;
            & .title{
                & >div:nth-child(2){
                    border-left-color: white;
                    border-left-style: solid;
                    border-left-width: 3px;

                }
            }
            & .row{
                & >div:nth-child(1){
                    @media #{'(max-width: 350px)'}{
                        width: 16%;
                        min-width: 50px;
                    }
                }
                & >div:nth-child(2){
                    @media #{'(max-width: 350px)'}{
                        width: 84%;
                    }
                }
            }
            & .title{
                & >div{
                    font-family: $font-nps-bold;
                    font-size: large;
                    color: white;
                    background-color: #2a3a57;
                }
            }

            & .content{
                & .item, .time {
                    padding: 7px 10px;
                    display: flex;
                    align-items: center;
                }
                & .bLine, .time{
                    border-bottom: 1px solid #ddd; /* 얇은 회색 선을 추가 */
                }
            }
            & .spkL{
                & .spk{
                    display: flex;
                }
            }
        }
        & .bold_l{
            font-size: 19px;
            min-width: 60px;
            font-family: $font-nps-bold;
            @media #{$xs}{
                font-size: 15px;
            }
        }
        & .bold_m{
            font-family: $font-nps-bold;
            font-size: 15px;
            @media #{$xs}{
                font-size: 12px;
            }
        }
        & .regu_m{
            font-family: $font-nps-regular;
            font-size: 15px;
            @media #{$xs}{
                font-size: 12px;
            }
        }
        & .regu_s{
            font-family: $font-nps-regular;
            font-size: 12px;
            @media #{$xs}{
                font-size: 9px;
            }
        }

        & .d{
        }
        & .r401{
        }
        & .r402{
        }
        & .r403{
        }
        & .d4{
        }
    }
}
