/*----------------------------------------*/
/*  23. Login CSS START
/*----------------------------------------*/

.faq {
    &__area {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        width: 100%;

        .title {
            margin-bottom: 40px;

            @media #{$xs}{
                font-size: 25px;
                padding: 30px 0px;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 900px;

        @media #{$lg_gt}{
            width: 900px;
            padding-left: 0;
            padding-right: 0;
        }
        @media #{$lg_st}{
            width: 90vw;
            padding-left: 0;
            padding-right: 0;
        }

        .ant-space{
            width: 100%;
            .ant-space-item{
                width:100%;
                .ant-collapse-content-box{
                    @media #{$xs} {
                        padding: 6px;
                        p{
                            font-size: 14px;
                        }
                    }
                }
                .ant-collapse-header{
                    background-color: rgba(70, 73, 102, 0.89);
                    color:white;
                    width: 100%;
                    display: flex;
                    @media #{$xs} {
                        padding: 5px 10px;
                    }
                    .ant-collapse-header-text{
                        flex: auto;
                        .Q{
                            color: #75ebdc;
                            font-size: 19px;
                            font-weight: bold;
                            @media #{$xs} {
                                font-size: 16px;
                            }
                        }
                        .Q_text{
                            flex: auto;
                            font-size: 17px;
                            font-weight: 600;
                            @media #{$xs} {
                                font-size: 15px;
                                font-weight: 300;
                            }
                        }
                    }
                    .ant-collapse-expand-icon{
                        padding-inline-end: 4px;
                        .ant-collapse-arrow{
                            font-size: 18px;
                            @media #{$xs} {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }

        .pagination{
            padding-top: 20px;
        }
    }
}