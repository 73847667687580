/*----------------------------------------*/
/*  23. Login CSS START
/*----------------------------------------*/

.overview {
    padding-bottom: 0;

    &__area {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding: 60px 0 0;
    }

    &__title {
        @include section-title;
    }

    &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        padding: 0 40px 0;

        $base: 992px;
        $ratio: 5/8;
        @media #{$lg_st}{
            width: 90vw;
            padding-left: 0px;
            padding-right: 0px;
        }

        & section {
            margin-bottom: 50px;
        }
    }

    &__textInfo {
        tr {
            height: 50px;
            margin-bottom: 5px;
            display: flex;
            justify-items: baseline;
        }

        .flex-column {
            margin-bottom: 20px;

            .item {
                @include data-label;
                margin-right: 15px;
                width: 85px;
            }

            .desc {
                display: flex;
                justify-content: space-between;
                font-family: $font-lato;
                background-color: #f8f8fc;
                padding: 12px;
                border-radius: 12px;
                font-size: 14px;
                line-height: 1.6;
                color: #333;
                flex: 1;
                @media #{$xs} {
                    width: 180px;
                    flex-direction: column;
                }

                .desc-sub {
                    font-size: 12px;
                    color: #666;
                    margin-top: 8px;
                }

                .colored-png-container {
                    padding: 5px 10px;
                    background-color: #1f469e61;
                    width: fit-content;
                    height: fit-content;
                    border-radius: 18px;

                    .colored-png {
                        width: 250px;
                        height: 40px;
                        @media #{$xs} {
                            width: 180px;
                        }
                    }
                }
            }

            .directions {
                width: fit-content;
                padding: 4px 7px;
                font-size: 14px;
                color: white;
                background-color: #1f4a9e;
                border-color: #1f4a9e;
                border-width: 2px;
                border-style: solid;
                margin-left: 15px;
                text-decoration: none;
                line-height: 1.2;
                border-radius: 9px;
                @media #{$xs} {
                    margin-left: 0px;
                    margin-top: 5px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__schedule {
        padding-bottom: 0;

        .contentBox {
            display: flex;
            flex-direction: column; /* 수직 방향으로 요소들을 배치 */
            justify-content: center; /* 수직 가운데 정렬 */
            min-height: 100px;
            min-width: 140px;
            padding: 20px 0px; /* 내용과 테두리 사이 여백 설정 */
            .item {
                margin-bottom: 13px;

                .title {
                    font-size: large;
                    color: black;
                    font-weight: bold;
                }

                .ho {
                    font-size: 20px;
                    background-color: #e4e9f1;
                    width: fit-content;
                    padding: 3px 5px;
                    line-height: 1.2;
                    color: black;
                    font-weight: bold;
                }

                .ho-color1 {
                    background-color: $color-401-ho;
                }

                .ho-color2 {
                    background-color: $color-402-ho;
                }

                .ho-color3 {
                    background-color: $color-403-ho;
                }

            }
        }

        li {
            /* TimelineOppositeContent */
            > div:nth-child(1) {
                font-family: $font-nps-bold;
                font-size: 0.9rem;
            }

            /* TimelineSeparator */
            > div:nth-child(2) span {
                background-color: #3b8bdb;
                border-color: #7adb4d;
            }

            /* TimelineContent */
            > div:nth-child(3) {
                padding-top: 2rem;
            }
        }

        .ops {
            opacity: 0;
        }

        .last {
            min-height: 0px;
            padding: 10px 0 0 0;
        }
    }

    &__poster {
        width: 100%;

        .poster {
            width: 100%;
            object-fit: cover;
        }
    }

}