/*----------------------------------------*/
/*  23. Login CSS START
/*----------------------------------------*/

.login{
    & a{
        position: relative;
    }
    &__area{
        display: flex;
        justify-content: center;
        padding: 70px 0;
    }
    &__widget{
        max-width: 600px;
        padding: 43px 40px;
        padding-bottom: 45px;
        @media #{$lg}{
            padding-left: 25px;
            padding-right: 25px;
        }
        @media #{$xs}{
            padding-left: 25px;
            padding-right: 25px;
        }
        &-title{
            & h4{
                font-size: 24px;
                margin-bottom: 20px;
            }
        }
        &_content{

        }
    }
    &__form{
        & input{
            width: 100%;
            height: 50px;
            border: 2px solid transparent;
            background: $white;
            @include border-radius(10px);
            padding: 0 20px;
            margin-bottom: 20px;
            &::placeholder{
                color: $grey-20;
            }
            &:focus{
                border-color: $theme-color;
                outline: none;
                &::placeholder{
                    opacity: 0;
                }
            }
        }
    }


}