/*----------------------------------------*/
/*  03. SLIDER CSS START
/*----------------------------------------*/

.single-slider{
    position: relative;
    @include background();
    &-2{
        &::before{
            display: none;
        }
        &.slick-active{
            & .slider__content-2{
                &::before{
                    width: 250px;
                    @media #{$xs}{
                        width: 200px;
                    }
                }
            }
        }
    }
}

.slick-active .single-slider-2 .slider__content-2::before {
    width: 250px;
}

.slider{
    &__area{
        position: relative;
        & .slick-dots{
            display: none;
        }
        &-2{
            & .slick-dots{
                display: inline-block !important;
                position: absolute;
                bottom: 30px;
                left: 140px;
                & li{
                    display: inline-block;
                    &.slick-active{
                        & button{
                            background: $white;
                        }
                    }
                    & button{
                        font-size: 0;
                        width: 14px;
                        height: 14px;
                        background: transparent;
                        border: 2px solid rgba($color: $white, $alpha: .3);
                        display: inline-block;
                        @include border-radius(50%);
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    &__height{
        min-height: 950px;
        @media #{$sm}{
            min-height: 700px;
        }
        @media #{$xs}{
            min-height: 600px;
        }
        &-2{
            @media #{$md}{
                min-height: 800px;
            }
        }
    }
    &__content{
        position: relative;
        z-index: 1;
        & > span{
            color: $grey-3;
            font-size: 20px;
            display: inline-block;
            margin-bottom: 17px;
        }
        & h1{
            font-size: 70px;
            color: $white;
            font-weight: 900;
            margin-bottom: 38px;
            @media #{$md}{
                font-size: 50px;
            }
            @media #{$sm}{
                font-size: 40px;
            }
            @media #{$xs}{
                font-size: 30px;
            }
        }
        &-2{
            position: relative;
            & > span{
                padding: 4px 10px;
                color: #e6e6e6;
                background: rgba($color: $white, $alpha: .1);
                @include border-radius(6px);
                display: inline-block;
                margin-bottom: 10px;
                font-size: 16px;
            }
            & h1{
                margin-bottom: 15px;
                @media #{$laptop}{
                    font-size: 60px;
                }
                @media #{$lg}{
                    font-size: 60px;
                }
            }
            & p{
                color: #e6e6e6;
                margin-bottom: 45px;
            }
            &::before{
                position: absolute;
                content: '';
                left: -100px;
                top: -115px;
                width: 0;
                height: 600px;
                background-image: -moz-linear-gradient( -120deg, rgb(255,36,247) 0%, rgb(137,35,255) 100%);
                background-image: -webkit-linear-gradient( -120deg, rgb(255,36,247) 0%, rgb(137,35,255) 100%);
                background-image: -ms-linear-gradient( -120deg, rgb(255,36,247) 0%, rgb(137,35,255) 100%);
                background-image: linear-gradient( -120deg, rgb(255,36,247) 0%, rgb(137,35,255) 100%);
                opacity: .8;
                z-index: -1;
                border-top-left-radius: 100px;
                @media #{$lg}{
                    top: -90px;
                    height: 500px;
                }
                @media #{$md}{
                    top: -90px;
                    height: 500px;
                }
                @media #{$sm}{
                    top: -55px;
                    height: 400px;
                }
                @media #{$xs}{
                    top: -65px;
                    height: 400px;
                    left: auto;
                    right: 0px;
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                    -moz-transform: scaleX(-1);
                    -ms-transform: scaleX(-1);
                    -o-transform: scaleX(-1);
                }
            }
        }
        &-3{
            &::before{
                display: none;
            }
            & p{
                display: inline-block;
                margin-bottom: 40px;

            }
        }
        &-4{
            padding-right: 65px;
            & p{
                margin-bottom: 55px;
                line-height: 30px;
            }
        }
        &-5{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 82%;
            & img{
                max-width: 100%;
                height: auto;
            }
        }
    }
    &__play{
        @media #{$xs}{
            margin-top: 30px;
        }
        &-btn{
            display: inline-block;
            width: 90px;
            height: 90px;
            line-height: 86px;
            text-align: center;
            background: transparent;
            font-size: 25px;
            color: $white;
            border: 2px solid rgba($color: $white, $alpha: .2);
            @include border-radius(50%);
            animation: pulse 2s infinite;
            &:hover{
                background: $white;
                color: $black;
                border-color: $white;
            }
        }
    }
    &__shape{
        & img{
            position: absolute;
            &.triangle{
                right: 28%;
                bottom: 38%;
                animation: triangle 5s linear 0s infinite alternate;
                -webkit-animation: triangle 5s linear 0s infinite alternate;
            }
            &.dotted-square{
                right: 22%;
                bottom: 31%;
                animation: dottedSquare 8s linear 0s infinite alternate;
                -webkit-animation: dottedSquare 8s linear 0s infinite alternate;
            }
            &.solid-square{
                bottom: 19%;
                right: 25%;
                z-index: 1;
                animation: solidsquare 5s linear 0s infinite alternate;
                -webkit-animation: solidsquare 5s linear 0s infinite alternate;
            }
            &.circle{
                right: 10%;
                bottom: -47%;
                animation: sliderCircle 7s linear 0s infinite alternate;
                -webkit-animation: sliderCircle 7s linear 0s infinite alternate;
            }
            &.circle-2{
                right: 12%;
                top: 65%;
                animation: sliderCircle 7s linear 0s infinite alternate;
                -webkit-animation: sliderCircle 7s linear 0s infinite alternate;
            }

        }
        &-1{
            top: -120px;
            left: -100px;
            z-index: -1;
        }
    }
}

/* slider shapw keyframe */


@keyframes triangle{
    0%{
      transform: translateY(-100px);
      -webkit-transform: translateY(-100px);
      -moz-transform: translateY(-100px);
      -ms-transform: translateY(-100px);
      -o-transform: translateY(-100px);
    }
    100%{
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
    }
}

@keyframes dottedSquare{
  0%{
    transform: rotate(0deg)  ;
    -webkit-transform: rotate(0deg)  ;
    -moz-transform: rotate(0deg)  ;
    -ms-transform: rotate(0deg)  ;
    -o-transform: rotate(0deg)  ;
}
  100%{
    transform: rotate(360deg)  ;
    -webkit-transform: rotate(360deg)  ;
    -moz-transform: rotate(360deg)  ;
    -ms-transform: rotate(360deg)  ;
    -o-transform: rotate(360deg)  ;
}
}

@keyframes solidsquare{
    0%{
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
    }
    25%{
      transform: translateX(50px);
      -webkit-transform: translateX(50px);
      -moz-transform: translateX(50px);
      -ms-transform: translateX(50px);
      -o-transform: translateX(50px);
    }

    100%{
      transform: translateY(100px);
      -webkit-transform: translateY(100px);
      -moz-transform: translateY(100px);
      -ms-transform: translateY(100px);
      -o-transform: translateY(100px);
    }
}
@keyframes sliderCircle{
    0%{
      transform: rotate(0);
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
}

    100%{
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
}
}


/* pulse btn */
@-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}
@keyframes pulse {
0% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
}
70% {
	-moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	box-shadow: 0 0 0 45px rgba(255,255,255, 0);
}
100% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
}
}
.key-visual {
    position: relative;
    background-image: url(../img/2024design/keyvisual_background-1.webp);
    //background-repeat: no-repeat;
    width: 100%;
    height: 100vh; /* 화면 높이에 맞게 조정 */
    min-height: 540px;
    background-size: cover; /* 이미지가 배너 크기에 맞게 조정되도록 함 */
    background-position: center; /* 중앙에 이미지를 위치시킴 */
    @media screen and (orientation: landscape)  {
        min-height: 850px;
        @media #{$sm}{
            min-height: 650px;
        }
        @media #{$xs}{
            min-height: 550px;
        }
    }
    overflow: hidden;

    .key-visual-bg_layer {
        content: "";

        .layer-2 {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-image: url(../img/2024design/keyvisual_background-2.png);
            background-size: auto 100%;
            background-position: center;
        }
        .layer-3 {
            position: absolute;
            z-index: 3;
            transform: translate(-50%, -50%);
            width: 600px;
            height: 600px;
            max-width: 80vw;
            max-height: 80vw;
            top: 25%;
            left: 20%;
            background-image: url(../img/2024design/globe.png);
            background-size: contain;
            //background-position: ;
            background-repeat: no-repeat;
        }
    }

    /* 상단 로고 */
    .wrapper-for_keyvisual_top{
        position: absolute;
        width: 50vw;
        max-width: 700px;
        height: 100vh;
        transform: translateX(50%);

        .keyvisual-top{
            position: absolute;
            transform: translateX(-50%) translateY(-50%); /* 기준 위치 조정 */
            top: 15%; /* 상단에서부터 10% 위치에 배치 */
            right: 50%;
            height: auto; /* 비율 유지 */
            width: 250px; /* 상대적 크기 조정 */
            @media #{$sm} {
                width: 220px;
            }
            @media #{$xs} {
                width: 200px;
            }
        }
    }

    .wrapper-3{
        width: 100%;
        height: 100%;
        position: relative;
        .keyvisual-top{
            position: absolute;
            transform: translateX(0%) translateY(-100%); /* 기준 위치 조정 */
            top: 0; /* 상단에서부터 10% 위치에 배치 */
            right: 0%;
            height: auto; /* 비율 유지 */
            width: 160px; /* 상대적 크기 조정 */
            @media #{$sm} {
                width: 130px;
                top: 0px;
            }
            @media #{$xs} {
                width: 100px;
                top: 0px;
            }
        }
    }

    /* 키비주얼 로고, 하단 로고 스타일 */
    .slider__content-6{
        position: relative;
        height: 100%;

        .keyvisual-mid {
            position: absolute;
            left: 50%; /* 중앙 정렬 */
            transform: translateX(-50%) translateY(-50%); /* 정확한 중앙 위치 조정 */
        }

        .keyvisual-mid {
            top: calc(max(50%, 350px)); /* 상단에서부터 40% 위치에 배치 */
            height: auto; /* 비율 유지 */
            width: 960px; /* 상대적 크기 조정 */
            gap:30px;
            @media #{$lg_st} {
                width: 95vw;
            }
            @media #{$xs} {
                top: calc(max(50%, 300px));
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            .mid1 {
                width: 100%;
            }
            .mainBannerBtn {
                width: 100%;
                //height: auto; /* 비율 유지 */
                .preRegistration{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .info{
                        margin-top: 5px;
                        font-size: 20px;
                        color:white;
                        @media #{$md}{
                            font-size: 17px;
                        }
                        @media #{$sm}{
                            font-size: 14px;
                        }
                        @media #{$xs}{
                            font-size: 13px;
                        }
                    }
                }
            }
            .mid2 {
                width: 90%;
                text-align: center;
                @media #{$sm} {
                    width: 100%;
                }
                @media #{$xs} {
                    width: 100%;
                }
            }
            .keyvisual-bottom {
                //top: 88%; /* 상단에서부터 90% 위치에 배치 */
                height: auto; /* 비율 유지 */
                width: 540px; /* 상대적 크기 조정 */
                @media #{$sm} {
                    width: 490px;
                }
                @media #{$xs} {
                    width: 95vw;
                }
            }
        }



    }
}
.home_background-2 {
    z-index: -10;
    background: url(../img/2024design/keyvisual_background-2.png);
    position: absolute;
    //background-repeat: no-repeat;
    //width: 100%;
    display: block;
    height: 100vh; /* 화면 높이에 맞게 조정 */
    //background-size: cover; /* 이미지가 배너 크기에 맞게 조정되도록 함 */
    background-position: center; /* 중앙에 이미지를 위치시킴 */
    @media screen and (orientation: landscape) {
        min-height: 850px;
    }
}
.h1_slider_wrapper{
    position: relative;
    z-index: 5;
}

/*fadein custom*/
@-webkit-keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp2 {
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}



.slick-active .slider__content span,.slick-active .slider__content h1,.slick-active .slider__content p,.slick-active .slider__content .slider__btn,.slick-active .hero-slider-btn,.slick-active .h4-span {
    -webkit-animation-name: fadeInUp2;
    animation-name: fadeInUp2;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.slick-active .slider__content span {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
.slick-active .slider__content h1{
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
}
.slick-active .slider__content p{
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
}
.slick-active .slider__content .slider__btn{
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
}
.hero__content{
    & span{
        -webkit-animation-name: fadeInUp2;
        animation-name: fadeInUp2;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
    }
    & h1{
         -webkit-animation-name: fadeInUp2;
        animation-name: fadeInUp2;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-delay:0.5s;
        animation-delay:0.5s;
        -webkit-animation-duration:0.5s;
        animation-duration:0.5s;
    }
    & p{
         -webkit-animation-name: fadeInUp2;
        animation-name: fadeInUp2;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-delay:0.7s;
        animation-delay:0.7s;
        -webkit-animation-duration:0.7s;
        animation-duration:0.7s;
    }
    & a{
         -webkit-animation-name: fadeInUp2;
        animation-name: fadeInUp2;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-delay:0.9s;
        animation-delay:0.9s;
        -webkit-animation-duration:0.9s;
        animation-duration:0.9s;
    }
}
