/*----------------------------------------*/
/*  23. Login CSS START
/*----------------------------------------*/
.baseBG{
    position: fixed;
    top: 0;
    left:0;
    z-index: -20;
    width: 100%;
    height: 100vh; /* 화면 높이에 맞게 조정 */
    overflow: hidden;
    display:block;
    background: url(../img/2024design/2023.webp) no-repeat center;
    background-size: cover;  /* 높이를 100%로 맞추고 너비는 자동 조정 */
    @media screen and (orientation: landscape) {
        min-height: 850px;
    }
}
.home{
    &__speakers{
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100vw;

        @media #{$lg} {
            padding: 0 30px;
        }
        @media #{$lg_st} {
            padding: 0;
        }
        &__cover {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            background-color: #dbecee;
            z-index: -1;
        }
        &__content {
            max-width: 1800px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            margin-top: 50px;

            .title {
                font-size: 30px;
                margin-bottom: 10px;
                @media #{$xs} {
                    font-size: 23px;
                }
                &-2{
                    font-size: 23px;
                    margin-bottom: 30px;
                    color: gray;
                    @media #{$xs} {
                        font-size: 15px;
                    }
                }
            }

            section{
                margin-bottom: 40px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

            }
            .profiles{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            .detailBtn{
                width: fit-content;
                padding: 6px 9px;
                font-size: 20px;
                color: white;
                background-color: #5a82a5;
                line-height: 1.2;
                border-radius: 20px;
                border-color: #dfe5f3;
                border-width: 2px;
                border-style: solid;
                margin-bottom: 50px;
            }
        }
    }

    &__program{
        width: 100vw;
        background-color: white;
    }
    &__area{
        display: flex;
        justify-content: center;
        padding: 60px 0;
        & h1{
            text-align: center;
        }
    }
    &__title{
        text-align: center;
        margin-bottom: 40px;
    }
    &__content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 1200px;
        padding: 0 40px 0;

        @media #{$lg_gt}{
            width: 1100px;
            padding-left: 0px;
            padding-right: 0px;
        }
        @media #{$lg_st}{
            width: 90vw;
            padding-left: 0px;
            padding-right: 0px;
        }
        & .ant-image{
            width: 100%;
        }
        & .imgBox{
            width: 100%;
            & img{
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

//추가 커스텀
.home__speakers__content> .profiles>.ProfileCard:nth-child(13) .prfPosition{
    font-size: 11px !important;
}
.home__speakers__content> .profiles>.ProfileCard:nth-child(4) .up-visible,
.home__speakers__content> .profiles>.ProfileCard:nth-child(4) .down-visible{
    font-size: 14px !important;
    padding-bottom: 0;
}
.home__speakers__content> .profiles>.ProfileCard:nth-child(15) .up-visible{
    font-size: 14px !important;
}