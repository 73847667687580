/*----------------------------------------*/
/*  23. Login CSS START
/*----------------------------------------*/

.noticeDetail {
    &__area {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        width: 100%;

        li{
            list-style: none;
        }
        li { position: relative; padding-left: 20px; /* 동그라미와 텍스트 간격 */ }
        li::before { content: ''; position: absolute; top: 50%; left: 0; width: 10px; height: 10px; background-color: black; /* 꽉 찬 동그라미 색상 */ border-radius: 50%; /* 동그라미 모양 */ transform: translateY(-50%); }

        .title {
            margin-bottom: 40px;

            @media #{$xs}{
                font-size: 25px;
                padding: 30px 0px;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 900px;

        @media #{$lg_gt}{
            width: 900px;
            padding-left: 0;
            padding-right: 0;
        }
        @media #{$lg_st}{
            width: 90vw;
            padding-left: 0;
            padding-right: 0;
        }

        .titleDiv{
            display: flex;
            width: 100%;
        }
        .star{
            display: flex;
            align-items: center;
            font-size: x-large;
            color: black;
        }
        .postTitle {
            width: 100%;
            text-align: left;
            margin-bottom: 0;
            font-size: 16px;
            background-color: #2e3259cf;
            color: white;
            padding: 11px 15px;
            font-family: "Lato", sans-serif;;
            border-radius: 5px;

            @media #{$xs}{
                font-size: 13px;
                padding: 9px 13px;
            }
        }

        .subInfo {
            width: 100%;
            border-bottom: #2c234d solid 2px;
            font-size: 16px;
            font-weight: 600;
            padding: 11px 15px 24px;
            border-bottom: #2c234d solid 2px;
            font-family: system-ui;

            @media #{$xs}{
                font-size: 11.5px;
                p {
                    font-size: 11.5px;
                }
            }

            p {
                display: inline;
            }

            .rightBox {
                display: flex;
                flex-direction: column;
                width: fit-content;
                float: right;

                @media #{$xs}{
                    float: none;
                }
            }

            .author {
                display: inline;
                text-align: left;
            }

            .views, .date {
                text-align: left;
            }
        }
        .postContent {
            width: 100%;
            padding: 20px 10px 10px;
            border-bottom: #2c234d solid 2px;

            @media #{$xs}{
                font-size: 11.5px;
                p {
                    font-size: 11.5px;
                }
            }
        }
        .postFooter{
            position: relative;
            width: 100%;
            padding-top: 10px;
            .btn{
                position: absolute;
                display: flex;
                padding: 5px;
                background-color: #2e325996;
                color: white;
                border-radius: 10px;
                font-size: 11px;
                word-break: keep-all;
            }
            .prevBtn{
                left: 0;
            }
            .listBtn{
                left:50%;
                transform: translateX(-50%);
            }
            .nextBtn{
                left:100%;
                transform: translateX(-100%);
            }
        }
    }
}