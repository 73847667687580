/*----------------------------------------*/
/*  23. Login CSS START
/*----------------------------------------*/

.notice {
  &__area {
    display: flex;
    justify-content: center;
    padding: 60px 0;

    & h1 {
    }
  }

  &__title {
    margin-bottom: 40px;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 900px;

    @media #{$lg_gt}{
      width: 900px;
    }
    @media #{$lg_st}{
      width: 90vw;
    }

    .star{
      font-size: large;
    }
    .ant-table-wrapper{
      width: 100%;
    }
    .ant-table-thead .ant-table-cell{
      background-color: rgba(70, 73, 102, 0.89);
      color: white;
      padding: 5px 16px;
      font-family: "Lato", sans-serif;;
    }
    @media #{$sm_gt}{
      .ant-table-thead .ant-table-cell {
        @media #{$sm}{
          font-size: 12px;
          padding: 10px;
          min-width: 50px;
        }
      }

      .ant-table-tbody .ant-table-cell {
        word-break: break-all;
        font-family: $font-nps-regular;
        font-size: 12px;
        @media #{$sm} {
          font-size: 11px;
          padding: 10px;
        }
      }
      .ant-table-cell:nth-child(1) {
        width: 70px;
        @media #{$sm}{
          width: 50px;
        }
      }
      .ant-table-cell:nth-child(2) {
        font-family: $font-lato;
        font-weight: bold;
        font-size: 13px;
      }
      .ant-table-cell:nth-child(3) {
        width: 95px;
        @media #{$sm}{
          width: 70px;
        }
      }
      .ant-table-cell:nth-child(4) {
        width: 95px;
        @media #{$sm}{
          width: 60px;
        }
      }
      .ant-table-cell:nth-child(5) {
        width: 95px;
        @media #{$sm}{
          width: 77px;
        }
      }
    }

    //모바일
    @media #{$xs} {
      .m{
        &-titleDiv{
          display: flex;
        }
        &-star{
          font-size: large;
        }
        &-title{
          width: 100%;
          text-align: left;
          overflow: hidden;
          word-break: break-all;
          line-height: 1.4;
          box-sizing: border-box;
          font-weight: bold;
          font-family: $font-lato;
          margin-bottom: 8px;
          border-radius: 15px;
          padding: 0.5em;
          background-color: #2e3259cf;
          color: white;
          font-size: 12px;
        }
        &-shortbox{
          margin-left: 0.2em;
          margin-right: 0.4rem;
          margin-bottom: 0.5rem;
          display: inline-block;
          padding: 0.3em;
          background-color: rgb(237 238 238 / 44%);
          border-radius: 7px;
          font-size: 12px;
        }
      }
      .ant-table-tbody{
          padding: 0 16px;
      }
      .ant-table-tbody .ant-table-cell{
          padding: 16px 0;
          border-bottom: 1px solid #cac5c5;
      }
    }
  }
}